import companyThemes from "styles/companyThemes";

const DEV_URL = "https://dev-rp-databroker-api.datatrail.com";
const PROD_URL = "https://prod-rp-databroker-api.datatrail.com";

const DEV_NODE_URL = "https://dt-dev-node-server.azurewebsites.net";
const PROD_NODE_URL = "https://dt-prod-node-server.azurewebsites.net";

/********************************* DEVELOPMENT SETTINGS *************************************/
// NOTE: uncomment a different origin to spoof that hostname from localhost dev environment

// const DEV_ORIGIN = "app.mitrail.com";
// const DEV_ORIGIN = "dt-staging-mitrail.azurewebsites.net";
const DEV_ORIGIN = "dt-dev-mitrail.azurewebsites.net";
// const DEV_ORIGIN = "next.fleetbridge.com";
// const DEV_ORIGIN = "dt-staging-fleetbridgenext.azurewebsites.net";
// const DEV_ORIGIN = "dt-dev-fleetbridge.azurewebsites.net";
// const DEV_ORIGIN = "safetracks-staging.azurewebsites.net";
// const DEV_ORIGIN = 'track.safetracksgps.ca';
// const DEV_ORIGIN = "app.bluefoxtracking.com";
// const DEV_ORIGIN = "bluefox-staging.azurewebsites.net";
// const DEV_ORIGIN = "canadream.fleetbridge.com";

export const ORIGIN =
  window.location.hostname === "localhost"
    ? DEV_ORIGIN
    : window.location.hostname;

// NOTE: force 'isDevelopmentEnv' to 'false' to use production data in dev environment
const isDevelopmentEnv =
  process.env.NODE_ENV === "development" || ORIGIN.includes("dt-dev");
// const isDevelopmentEnv = false;

// URL's that have ecommerce features enabled
export const ECOMMERCE_URLS = [
  "app.mitrail.com",
  "dt-staging-mitrail.azurewebsites.net",
  "dt-dev-mitrail.azurewebsites.net",

  "next.fleetbridge.com",
  "dt-staging-fleetbridgenext.azurewebsites.net",
  "dt-dev-fleetbridge.azurewebsites.net",
];

/********************************************************************************************/

// development environment overrides
const devEnvironment = {
  API_URL: DEV_URL,
  NODE_API_URL: DEV_NODE_URL,
  ALLOW_REGISTRATION: true,
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyBNATwHpGglL_PLyzgUay9LLlW2xREa-bU",
    authDomain: "datatrail-dev.firebaseapp.com",
    projectId: "datatrail-dev",
    storageBucket: "datatrail-dev.appspot.com",
    messagingSenderId: "867946475549",
    appId: "1:867946475549:web:d7b0a693742648d54356f4",
    measurementId: "G-XVQ8K79WE7",
  },
};

// default environment
const defaultEnvironment = {
  // TODO: Update this to prod
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyBM4vQ5sBRejPFTyCbUCDpfm1GD9QLZ_9Y",
    authDomain: "datatrail-265c7.firebaseapp.com",
    projectId: "datatrail-265c7",
    storageBucket: "datatrail-265c7.appspot.com",
    messagingSenderId: "451126504482",
    appId: "1:451126504482:web:aa837f6f71e65439fe2e7b",
    measurementId: "G-L1CL6K64ZG",
  },
  GA_MEASUREMENT_ID: "G-43GF71KW6X",
  GOOGLE_API_KEY: "AIzaSyDT-a2zxVqCNBD9i-s1hitvUD_oudMa3J0",
  LANGUAGE_SWITCHER_IN_NAVBAR: true,
  SHOW_PL_IN_LANGUAGE_SWITCHER: true,
  THEME: companyThemes.fleetbridge,
  NODE_API_URL: isDevelopmentEnv ? DEV_NODE_URL : PROD_NODE_URL,
  API_URL: isDevelopmentEnv ? DEV_URL : PROD_URL,
  BRANCH_LIVE_KEY: "key_live_gi0PeDdX7LubCUS70HZNyidkFBjZ7BUX",
  SENTRY_DSN_URL:
    "https://9117d89e705d4a41bb3db8cde6ed45bd@o4503967252545536.ingest.sentry.io/4503968365543424",
  TITLE: "FLEETBridge",
  HELP_LINK: "https://info.fleetbridge.com",
  DOCS: "https://content.fleetbridge.com/documents/next.fleetbridge.pdf",
  ALLOW_REGISTRATION: false,
  STRIPE_KEY:
    "pk_test_51GqLblGmTvzZBUmbU6VugRKJA9BDvZXa0ONsenxHRv7g6wLC4vUdF5tcQ2vN3X5YATT7VA0tjexRoYgAJO3kAHzN00fAZVNzbH",
};

// environment specific overrides
const environments = {
  // FLEETBridge
  "next.fleetbridge.com": {
    GOOGLE_API_KEY: "AIzaSyBi-xYqnCxoC2HJvfGnHkWB_-rYGWQ43z8",
    GA_MEASUREMENT_ID: "G-HENKNBBKCC",
    ALLOW_REGISTRATION: true,
    STRIPE_KEY:
      "pk_live_51PIyKDBvBuzz221flALNzo2lin5Z5mwe1hJSzOcnf9BwPeAVL3jctAvwBqTBBCVG10EVb5hIbT6YMTTKd139QuaZ00uyqY3oYu",
    SUPPORT_EMAIL: "support@fleetbridge.com",
  },
  "dt-staging-fleetbridgenext.azurewebsites.net": {
    GOOGLE_API_KEY: "AIzaSyBsMO8YiiQ1dErB2Wb_KHlMX9nuDC4ELH8",
    TITLE: "FLEETBridge Staging",
    ALLOW_REGISTRATION: true,
    STRIPE_KEY:
      "pk_live_51PIyKDBvBuzz221flALNzo2lin5Z5mwe1hJSzOcnf9BwPeAVL3jctAvwBqTBBCVG10EVb5hIbT6YMTTKd139QuaZ00uyqY3oYu",
    SUPPORT_EMAIL: "support@fleetbridge.com",
  },
  "dt-dev-fleetbridge.azurewebsites.net": {
    TITLE: "FLEETBridge Dev",
    NODE_API_URL: DEV_NODE_URL,
    API_URL: DEV_URL,
    ALLOW_REGISTRATION: true,
    STRIPE_KEY:
      "pk_test_51PIyKDBvBuzz221fwYHGcJFsETUfTIG6HrrSOj9qdXRXWZTfAnfS0dLzBcriKgzyCkJjTVQKFlh0j3Cz3wdBDWZ80059vvTV6i",
    SUPPORT_EMAIL: "support@fleetbridge.com",
  },

  // miTrail
  "app.mitrail.com": {
    GOOGLE_API_KEY: "AIzaSyDAwAjozdI1kEbIi0UO5OTgas7FACWjsXE",
    GA_MEASUREMENT_ID: "G-TDJ4X93XB9",
    THEME: companyThemes.mitrail,
    TITLE: "miTrail",
    BRANCH_LIVE_KEY: "key_live_koYAs7XeUMnH3qrQr4DkmlbdzAokNlMe",
    ALLOW_REGISTRATION: true,
    HELP_LINK: "https://info.mitrail.com",
    STRIPE_KEY: "pk_live_DH6XHwkITMj50SppgnRD3ZK5",
    DOCS: "https://info.mitrail.com",
    SUPPORT_EMAIL: "support@mitrail.com",
  },
  "dt-staging-mitrail.azurewebsites.net": {
    GOOGLE_API_KEY: "AIzaSyBsMO8YiiQ1dErB2Wb_KHlMX9nuDC4ELH8",
    THEME: companyThemes.mitrail,
    TITLE: "miTrail Staging",
    BRANCH_LIVE_KEY: "key_live_koYAs7XeUMnH3qrQr4DkmlbdzAokNlMe",
    ALLOW_REGISTRATION: true,
    HELP_LINK: "https://info.mitrail.com",
    STRIPE_KEY: "pk_live_DH6XHwkITMj50SppgnRD3ZK5",
    DOCS: "https://info.mitrail.com",
    SUPPORT_EMAIL: "support@mitrail.com",
  },
  "dt-dev-mitrail.azurewebsites.net": {
    THEME: companyThemes.mitrail,
    TITLE: "miTrail dev",
    GA_MEASUREMENT_ID: "G-43GF71KW6X",
    BRANCH_LIVE_KEY: "key_live_koYAs7XeUMnH3qrQr4DkmlbdzAokNlMe",
    ALLOW_REGISTRATION: true,
    API_URL: DEV_URL,
    NODE_API_URL: DEV_NODE_URL,
    DOCS: "https://info.mitrail.com",
    SUPPORT_EMAIL: "support@mitrail.com",
  },

  // Safetracksgps
  "track.safetracksgps.ca": {
    GOOGLE_API_KEY: "AIzaSyCHFl9eGVEFueKu-NMrRTb0AS1MYUjkxOA",
    THEME: companyThemes.safetracksgps,
    TITLE: "SafeTracks GPS",
    HELP_LINK: "https://www.safetracksgps.ca/contact",
  },
  "safetracks-staging.azurewebsites.net": {
    GOOGLE_API_KEY: "AIzaSyBsMO8YiiQ1dErB2Wb_KHlMX9nuDC4ELH8",
    THEME: companyThemes.safetracksgps,
    TITLE: "(STAGING) SafeTracks GPS",
    HELP_LINK: "https://www.safetracksgps.ca/contact",
  },

  // Blue fox Tracking
  "app.bluefoxtracking.com": {
    GOOGLE_API_KEY: "AIzaSyB6O6vub-N3sX7SkHnvhIUhCHM699nm19g",
    THEME: companyThemes.bluefox,
    TITLE: "Bluefox Tracking",
    HELP_LINK: "https://www.bluefoxtracking.com/contact",
  },
  "bluefox-staging.azurewebsites.net": {
    GOOGLE_API_KEY: "AIzaSyBsMO8YiiQ1dErB2Wb_KHlMX9nuDC4ELH8",
    THEME: companyThemes.bluefox,
    TITLE: "(STAGING) Bluefox Tracking",
    HELP_LINK: "https://www.bluefoxtracking.com/contact",
  },

  // Canadadream
  "canadream.fleetbridge.com": {
    GOOGLE_API_KEY: "AIzaSyDpuu-1pQk6aJdmw8-uiDmwQGRV3gX89Mo",
    THEME: companyThemes.canadream,
    TITLE: "CanaDream",
    ALLOW_REGISTRATION: false,
  },
  "canadream-staging.azurewebsites.net": {
    GOOGLE_API_KEY: "AIzaSyBsMO8YiiQ1dErB2Wb_KHlMX9nuDC4ELH8",
    THEME: companyThemes.canadream,
    TITLE: "(STAGING) CanaDream",
    ALLOW_REGISTRATION: false,
  },
};

const config = {
  ...defaultEnvironment,
  ...environments[ORIGIN],
  ...(isDevelopmentEnv ? devEnvironment : {}),
};

export const FIREBASE_CONFIG = config.FIREBASE_CONFIG;
export const API_URL = config.API_URL;
export const NODE_API_URL = config.NODE_API_URL;
export const GOOGLE_API_KEY = config.GOOGLE_API_KEY;
export const LANGUAGE_SWITCHER_IN_NAVBAR = config.LANGUAGE_SWITCHER_IN_NAVBAR;
export const SHOW_PL_IN_LANGUAGE_SWITCHER = config.SHOW_PL_IN_LANGUAGE_SWITCHER;
export const ICON_URL = config.ICON_URL;
export const THEME = config.THEME;
export const TITLE = config.TITLE;
export const HELP_LINK = config.HELP_LINK;
export const DOCS = config.DOCS;
export const ALLOW_REGISTRATION = config.ALLOW_REGISTRATION;
export const STRIPE_KEY = config.STRIPE_KEY;
export const SENTRY_DSN_URL = config.SENTRY_DSN_URL;
export const BRANCH_LIVE_KEY = config.BRANCH_LIVE_KEY;
export const GA_MEASUREMENT_ID = config.GA_MEASUREMENT_ID;
export const SUPPORT_EMAIL = config.SUPPORT_EMAIL;
export const IS_DEVELOPMENT_ENV = isDevelopmentEnv;

document.title = TITLE;
